import { render, staticRenderFns } from "./shareEmp.vue?vue&type=template&id=4e5470b2&scoped=true&"
import script from "./shareEmp.vue?vue&type=script&lang=js&"
export * from "./shareEmp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e5470b2",
  null
  
)

export default component.exports