<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>共享用工</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
<!--            <div style="overflow:hidden;">-->
<!--                <div style="float:left;">-->
<!--                    <el-form :inline="true" :model="searchform">-->
<!--                        <el-form-item label="公司名称">-->
<!--                            <el-input v-model="searchform.companyName" clearable size="small"></el-input>-->
<!--                        </el-form-item>-->
<!--                        <el-form-item label="">-->
<!--                            <el-button type="primary"  size="small" @click="search">搜索</el-button>-->
<!--                        </el-form-item>-->
<!--                    </el-form>-->
<!--                </div>-->
<!--                <div style="float:right;">-->
<!--                    <el-button v-if="user.roleId==37" type="primary" @click="openadd"  icon="el-icon-circle-plus-outline">添加</el-button>-->
<!--                </div>-->
<!--            </div>-->
            <div class="list" v-loading="loading">
                <el-table
                    :data="list"
                    border
                    :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                    stripe
                    style="width: 100%">
                    <el-table-column
                        prop="memberName"
                        label="公司名称"
                        width="260">
                    </el-table-column>
                    <el-table-column
                        prop="industryName"
                        label="用工类型">
                    </el-table-column>
                    <el-table-column
                        prop="needPerson"
                        width="130"
                        label="需求人数">
                    </el-table-column>
                    <el-table-column
                        prop="supplyStr"
                        align="center"
                        label="供方/需方">
                    </el-table-column>
                    <el-table-column
                        prop="mobile"
                        label="联系电话">
                    </el-table-column>
                    <el-table-column
                        prop="description"
                        label="描述">
                    </el-table-column>
                    <el-table-column
                        prop="createTime"
                        label="时间">
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        width="150"
                        label="操作">
                        <template slot-scope="scope">
                            <el-button type="danger"  @click="deleteitem(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <app-page :total="total" :pageSize="pageSize" :current="pageNo" @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
        </div>

        <pop-up :title="status==1?'添加':'编辑'" width="30%" :isshow="showpop" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-form  :model="form">
                    <el-form-item label="公司名称">
                        <el-input v-model="form.companyName" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="职业名称">
                        <el-input v-model="form.workName" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="招聘人数">
                        <el-input-number v-model="form.personNumber" controls-position="right"></el-input-number>
                    </el-form-item>
                    <el-form-item label="薪资">
                        <el-input v-model="form.wages" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话">
                        <el-input v-model="form.phone" size="small"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
        <pop-up :title="'审核'" width="30%" :isshow="showsh" :haveconfirm="true" @close="getclosesh" @confirm="getconfirmsh">
            <div slot="box">
                <el-radio v-model="shvalue" :label="1">通过</el-radio>
                <el-radio v-model="shvalue" :label="2">不通过</el-radio>
            </div>
        </pop-up>
    </div>
</template>
<script>
import _api from "@/api/index";
import apiurl from "@/api/public";
export default {
    data(){
        return{
            showsh:false,
            searchform:{companyName:null,},
            total:0,
            list:[],
            pageSize:15,
            loading:false,
            pageNo:1,
            status:1,
            showpop:false,
            shid:null,
            user:null,
            shvalue:0,
            form:{id:null,companyName:null,workName:null,personNumber:null,wages:null,phone:null}
        }
    },
    created(){
        this.user=JSON.parse(localStorage.getItem("userInfo"))
        this.getList()
    },
    methods:{
        deleteitem(row){
            _api.get('/admin/flexible/deleteById',{id:row.id}).then(res=>{
                if(res.code==200){
                    this.$message.success("删除成功！")
                    this.getList()
                }else{
                    this.$message.error("删除失败！")
                }
            })
        },
        gotosh(row){
            this.shid=row.id
            this.showsh=true
        },
        getclosesh(){
            this.showsh=false
            this.shid=null
            this.shvalue=0
        },
        getconfirmsh(){

            if(this.shvalue==0){
                this.$message.warning("请选择！")
                return
            }

            _api.get(apiurl.screenxamine,{id:this.shid,isDis:this.shvalue}).then(res=>{
                if(res.code==200){
                    this.$message.success("操作成功！")
                    this.getclosesh()
                    this.getList()
                }else{
                    this.$message.error("操作失败！")
                    this.getclosesh()
                }
            })
        },
        search(){
            this.pageNo=1
            this.getList()
        },
        getList(){
            this.loading=true
            _api.get('/admin/flexible',{size:this.pageSize,page:this.pageNo}).then(res=>{
                this.loading=false
                console.log(res)
                this.list=res.data.list || []
                this.total=res.data.total || 0

            })
        },
        gotoEdit(row){
            for(let k in this.form){
                this.form[k]=row[k]
            }
            this.status=2
            this.showpop=true
        },
        openadd(){
            this.status=1
            this.form={id:null,companyName:null,workName:null,personNumber:null,wages:null,phone:null}
            this.showpop=true
        },
        getconfirm(){
            if(this.status==1){
                _api.post(apiurl.screeninsert,this.form).then(res=>{
                    console.log(res)
                    if(res.code==200){
                        this.$message.success("添加成功！")
                        this.getclose()
                        this.getList()
                    }
                })
            }else{
                _api.post(apiurl.screenedit,this.form).then(res=>{
                    console.log(res)
                    if(res.code==200){
                        this.$message.success("编辑成功！")
                        this.getclose()
                        this.getList()
                    }
                })
            }
        },
        getclose(){
            this.showpop=false
            this.status=1
            this.form={id:null,companyName:null,workName:null,personNumber:null,wages:null,phone:null}
        },
        handchange(data){//分页Size变化
            this.pageSize=data
            this.pageNo=1
            this.getList()
        },
        currentchange(data){//当前页变化
            // console.log(data)
            this.pageNo=data
            this.getList()
        },
    }
}
</script>
<style scoped>
</style>
